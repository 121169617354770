<template>
  <ClientOnly>
    <NuxtPage></NuxtPage>
  </ClientOnly>
</template>

<script setup>
useHead({
  viewport: "width=device-width, initial-scale=1, maximum-scale=1",
  charset: "utf-8",
});
</script>
