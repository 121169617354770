export const state = () => ({
  list: [],
});

export const mutations = {
  queue(state, input) {},
  save(state, input) {},
  async load(state, input) {},
  async add(state, image) {},
  removeAll(state, input) {},
  async remove(state, image) {},
};
