import axios from "axios";

export const state = () => ({
  user: {
    jwt: null,
    permissions: {
      features: [],
      voices: [],
      allowedVoices: null,
      proVoicesAllowed: null,
    },
    uuidv4: null,
    subscribed: null,
    subscriptionId: null,
    subscriptionPlanId: null,
    lastUpdated: null,
    collabToken: "",
  },
});

export const getters = {
  getUser(state) {
    return state.user;
  },
};

export const mutations = {
  load(state, user) {
    var storedUser = localStorage.getItem("user");
    if (storedUser != null) {
      storedUser = JSON.parse(storedUser);
    }
    if (storedUser != null) {
      state.user = storedUser;
    }
  },
  update(state, newData) {
    if (newData.key != null && newData.value != null) {
      state.user[newData.key] = newData.value;
    }
  },
  async signIn(state, user) {
    state.user = user;
    localStorage.setItem("user", JSON.stringify(state.user));

    try {
      this.$axios
        .$post("/api/user-upsert", {
          event: {
            user: {
              email: user.email,
            },
          },
        })
        .then(function (response) {
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    } catch (err) {
      console.log(err);
    }
  },
  signOut(state, id) {
    state.user = {};
    if (process.client) {
      localStorage.removeItem("user");
    }
  },
};
