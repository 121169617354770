
export const state = () => ({
  list: [],
})

export const mutations = {
  save(state, input) {},
  async load(state, input) {},
  async add(state, image) {},
  async queue(state, image) {},
  removeAll(state, input) {},
  async remove(state, image) {},
}
