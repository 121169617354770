import {
  imagesVuexStore,
  preferencesVuexStore,
  previewsVuexStore,
  reactionsVuexStore,
  rendersVuexStore,
  userVuexStore,
  workspaceVuexStore 
} from "#imports"
const VuexStore = {
  modules: {
      images: {
        ...imagesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      preferences: {
        ...preferencesVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      previews: {
        ...previewsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      reactions: {
        ...reactionsVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      renders: {
        ...rendersVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      user: {
        ...userVuexStore,
        namespaced: true,
        modules: {},
      }
    ,
      workspace: {
        ...workspaceVuexStore,
        namespaced: true,
        modules: {},
      }
    }, 
  }
export default VuexStore