import { default as billingNsveidGiLMMeta } from "/usr/src/app/ui/pages/billing.vue?macro=true";
import { default as editorg15ylo4kCSMeta } from "/usr/src/app/ui/pages/docs/editor.vue?macro=true";
import { default as indexTyplaKA5YUMeta } from "/usr/src/app/ui/pages/docs/index.vue?macro=true";
import { default as error2RaqKaOgVEMeta } from "/usr/src/app/ui/pages/error.vue?macro=true";
import { default as apiuBaqi7saJiMeta } from "/usr/src/app/ui/pages/help/api.vue?macro=true";
import { default as indexxIw687mM4IMeta } from "/usr/src/app/ui/pages/help/index.vue?macro=true";
import { default as edit0d0tzrKPf3Meta } from "/usr/src/app/ui/pages/images/edit.vue?macro=true";
import { default as editor9XhDKFwZcBMeta } from "/usr/src/app/ui/pages/images/editor.vue?macro=true";
import { default as indexwjeW2qNadOMeta } from "/usr/src/app/ui/pages/images/index.vue?macro=true";
import { default as indexIJKagAdrkbMeta } from "/usr/src/app/ui/pages/index.vue?macro=true";
import { default as landing8uOGXqWdmMMeta } from "/usr/src/app/ui/pages/landing.vue?macro=true";
import { default as pricingDcyT9hMBy8Meta } from "/usr/src/app/ui/pages/pricing.vue?macro=true";
import { default as previewgJqjRG4LLUMeta } from "/usr/src/app/ui/pages/print/preview.vue?macro=true";
import { default as promptsCyCkxKIbq1Meta } from "/usr/src/app/ui/pages/prompts.vue?macro=true";
import { default as speech_45to_45speechoy3kw9zEeUMeta } from "/usr/src/app/ui/pages/speech-to-speech.vue?macro=true";
import { default as subscribeXtDMDRbAf1Meta } from "/usr/src/app/ui/pages/subscribe.vue?macro=true";
import { default as text_45to_45speechHULYkPr4CEMeta } from "/usr/src/app/ui/pages/text-to-speech.vue?macro=true";
import { default as editorOEFTbdy8g5Meta } from "/usr/src/app/ui/pages/videos/editor.vue?macro=true";
export default [
  {
    name: "billing",
    path: "/billing",
    component: () => import("/usr/src/app/ui/pages/billing.vue").then(m => m.default || m)
  },
  {
    name: "docs-editor",
    path: "/docs/editor",
    component: () => import("/usr/src/app/ui/pages/docs/editor.vue").then(m => m.default || m)
  },
  {
    name: "docs",
    path: "/docs",
    component: () => import("/usr/src/app/ui/pages/docs/index.vue").then(m => m.default || m)
  },
  {
    name: "error",
    path: "/error",
    component: () => import("/usr/src/app/ui/pages/error.vue").then(m => m.default || m)
  },
  {
    name: "help-api",
    path: "/help/api",
    component: () => import("/usr/src/app/ui/pages/help/api.vue").then(m => m.default || m)
  },
  {
    name: "help",
    path: "/help",
    component: () => import("/usr/src/app/ui/pages/help/index.vue").then(m => m.default || m)
  },
  {
    name: "images-edit",
    path: "/images/edit",
    component: () => import("/usr/src/app/ui/pages/images/edit.vue").then(m => m.default || m)
  },
  {
    name: "images-editor",
    path: "/images/editor",
    component: () => import("/usr/src/app/ui/pages/images/editor.vue").then(m => m.default || m)
  },
  {
    name: "images",
    path: "/images",
    component: () => import("/usr/src/app/ui/pages/images/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/usr/src/app/ui/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "landing",
    path: "/landing",
    component: () => import("/usr/src/app/ui/pages/landing.vue").then(m => m.default || m)
  },
  {
    name: "pricing",
    path: "/pricing",
    component: () => import("/usr/src/app/ui/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: "print-preview",
    path: "/print/preview",
    component: () => import("/usr/src/app/ui/pages/print/preview.vue").then(m => m.default || m)
  },
  {
    name: "prompts",
    path: "/prompts",
    component: () => import("/usr/src/app/ui/pages/prompts.vue").then(m => m.default || m)
  },
  {
    name: "speech-to-speech",
    path: "/speech-to-speech",
    component: () => import("/usr/src/app/ui/pages/speech-to-speech.vue").then(m => m.default || m)
  },
  {
    name: "subscribe",
    path: "/subscribe",
    component: () => import("/usr/src/app/ui/pages/subscribe.vue").then(m => m.default || m)
  },
  {
    name: "text-to-speech",
    path: "/text-to-speech",
    component: () => import("/usr/src/app/ui/pages/text-to-speech.vue").then(m => m.default || m)
  },
  {
    name: "videos-editor",
    path: "/videos/editor",
    component: () => import("/usr/src/app/ui/pages/videos/editor.vue").then(m => m.default || m)
  }
]