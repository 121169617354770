export const state = () => ({
  list: [],
  currentWorkspace: {
    uuidv4: null,
    color: "#000000",
    title: "Personal Workspace",
  },
});

export const getters = {
  getCurrentWorkspace(state) {
    return state.currentWorkspace;
  },
};

export const mutations = {
  save(state, input) {},
  async load(state, input) {},
  async showAddWorkspaceModal(state, workspace) {},
  async select(state, workspace) {
    $nuxt.$set(state.currentWorkspace, "uuidv4", workspace.uuidv4);
    $nuxt.$set(state.currentWorkspace, "color", workspace.color);
    $nuxt.$set(state.currentWorkspace, "title", workspace.title);
  },
  async add(state, workspace) {
    state.list.push(workspace);
  },
  removeAll(state, input) {},
  async remove(state, image) {},
};
