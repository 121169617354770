export const state = () => ({
  list: [],
  preview: null,
});

export const mutations = {
  save(state, input) {},
  async load(state, input) {
    state.preview = localStorage.getItem("preview");
  },
  async add(state, input) {
    state.list.push(input);
    state.preview = input;
    localStorage.setItem("preview", input);
  },
  removeAll(state, input) {
    state.list = [];
    localStorage.setItem("preview", null);
  },
  async remove(state, image) {},
};
