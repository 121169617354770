export const state = () => ({
  preferences: {
    currentVoice: {
      voiceName: null,
      voiceId: null
    },
  },
  list: [
    'currentVoice',
  ],
})


export const getters = {
  getPreferences(state) {
    return state.preferences
  },
}

export const mutations = {
  load(state, newData) {        
    var storedPreferences = localStorage.getItem('preferences')
    console.log("Stored Preferences:");
    console.log(storedPreferences);
    
    
    if (storedPreferences != null) {
      storedPreferences = JSON.parse(storedPreferences)
    }    

    
    if (storedPreferences != null && storedPreferences.currentVoice != null) {
      console.log("Stored Voice:") 
      console.log(storedPreferences.currentVoice);
      state.preferences.currentVoice = 
          { voiceId: storedPreferences.currentVoice.voiceId, voiceName: storedPreferences.currentVoice.voiceName }      
    }
    
  },
  update(state, newData) {            
    var key = Object.keys(newData)[0];
    var val = Object.values(newData)[0];
    // console.log(state);
    // console.log(newData);
    // console.log(key);
    // console.log(val);

    if(state.list.includes(key)) { 
      
      if(key == 'currentVoice'){
          state.preferences['currentVoice'] = {voiceId: val.voiceId, voiceName: val.voiceName};
      }
    }

    localStorage.setItem('preferences', JSON.stringify(state.preferences));  
  },


  save(state, input) {},  
  async add(state, image) {},
  removeAll(state, input) {},
  async remove(state, image) {},
}
